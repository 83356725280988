<template>
  <div class="hw-subcard mx-auto p-3 shadow my-3" :class='[margin]'>
    <h2 class='mb-0 float-left'>{{title}}</h2>
    <img :src="image" class='float-right d-block' style='max-width:36px;margin-top:-6px;' alt="">
</div>
</template>

<script>
export default {
    props:['title','image','margin']
}
</script>

<style>

</style>